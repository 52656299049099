import React from 'react';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import Layout from '../components/layout/Layout';

const IndexPage = ({ data }) => (
  <Layout>
    <Helmet>
      <meta charSet='utf-8' />
      <title>LightFeather - Articles</title>
    </Helmet>
    <ul>
      {data.allStrapiJob.nodes.map((document) => (
        <li key={document.id} className='text-center'>
          <h2>{document.jobTitle}</h2>
          <p>{document.homepageBlurb}</p>
          <p>{document.postingBlurb}</p>
          <p>{document.qualifications}</p>
          <p>{document.applicationLink}</p>
          <br></br>
        </li>
      ))}
    </ul>
  </Layout>
);

export default IndexPage;

export const pageQuery = graphql`
  query {
    allStrapiJob {
      nodes {
        id
        jobTitle
        homepageBlurb
        postingBlurb
        qualifications
        applicationLink
      }
    }
  }
`;
